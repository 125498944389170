// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-tooltip .ant-tooltip-inner {
  color: #000000;
  font-size: 14px;
  background-color: #ffffff;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Fip/revoke/index.less"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,eAAA;EACA,yBAAA;AACJ","sourcesContent":[".custom-tooltip .ant-tooltip-inner {\n    color: #000000; \n    font-size: 14px; \n    background-color: #ffffff; \n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
