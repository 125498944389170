// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.voteList::-webkit-scrollbar {
  width: 10px;
}
.voteList::-webkit-scrollbar-thumb {
  background-color: #eeeeee;
  border-radius: 5px;
}
.voteList::-webkit-scrollbar-track {
  background-color: #F9F9F9;
  padding-left: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/components/VoteList/index.less"],"names":[],"mappings":"AAeE;EACE,WAAA;AAdJ;AAiBE;EACE,yBAAA;EACA,kBAAA;AAfJ;AAkBE;EACE,yBAAA;EACA,iBAAA;AAhBJ","sourcesContent":["// Copyright (C) 2023-2024 StorSwift Inc.\n// This file is part of the PowerVoting library.\n\n// Licensed under the Apache License, Version 2.0 (the \"License\");\n// you may not use this file except in compliance with the License.\n// You may obtain a copy of the License at:\n// http://www.apache.org/licenses/LICENSE-2.0\n\n// Unless required by applicable law or agreed to in writing, software\n// distributed under the License is distributed on an \"AS IS\" BASIS,\n// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.\n// See the License for the specific language governing permissions and\n// limitations under the License.\n\n.voteList {\n  &::-webkit-scrollbar {\n    width: 10px;\n  }\n\n  &::-webkit-scrollbar-thumb {\n    background-color: #eeeeee;\n    border-radius: 5px;\n  }\n\n  &::-webkit-scrollbar-track {\n    background-color: #F9F9F9;\n    padding-left: 4px;\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
