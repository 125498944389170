// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-input {
  padding: 0;
  color: white;
  font-size: 16px;
  background: transparent;
}
.ant-input:hover {
  border-color: transparent;
  background: transparent;
}
.ant-input-number {
  border: none;
  background: transparent;
}
.ant-input-number:hover {
  border-color: transparent;
  background: transparent;
}
.ant-input-number .ant-input-number-input {
  padding: 0;
  color: white;
  font-size: 16px;
  text-align: center;
  background: transparent;
}
.ant-input-number.ant-input-number-focused {
  box-shadow: none;
  border-color: transparent;
  background: transparent;
}
.ant-input-number.ant-input-number-focused:hover {
  border-color: transparent;
  background: transparent;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Vote/index.less"],"names":[],"mappings":"AAcA;EACE,UAAA;EACA,YAAA;EACA,eAAA;EACA,uBAAA;AAbF;AAcE;EACE,yBAAA;EACA,uBAAA;AAZJ;AAgBA;EACE,YAAA;EACA,uBAAA;AAdF;AAeE;EACE,yBAAA;EACA,uBAAA;AAbJ;AAQA;EAQI,UAAA;EACA,YAAA;EACA,eAAA;EACA,kBAAA;EACA,uBAAA;AAbJ;AAeE;EACE,gBAAA;EACA,yBAAA;EACA,uBAAA;AAbJ;AAcI;EACE,yBAAA;EACA,uBAAA;AAZN","sourcesContent":["// Copyright (C) 2023-2024 StorSwift Inc.\n// This file is part of the PowerVoting library.\n\n// Licensed under the Apache License, Version 2.0 (the \"License\");\n// you may not use this file except in compliance with the License.\n// You may obtain a copy of the License at:\n// http://www.apache.org/licenses/LICENSE-2.0\n\n// Unless required by applicable law or agreed to in writing, software\n// distributed under the License is distributed on an \"AS IS\" BASIS,\n// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.\n// See the License for the specific language governing permissions and\n// limitations under the License.\n\n.ant-input {\n  padding: 0;\n  color: white;\n  font-size: 16px;\n  background: transparent;\n  &:hover {\n    border-color: transparent;\n    background: transparent;\n  }\n}\n\n.ant-input-number {\n  border: none;\n  background: transparent;\n  &:hover {\n    border-color: transparent;\n    background: transparent;\n  }\n  .ant-input-number-input {\n    padding: 0;\n    color: white;\n    font-size: 16px;\n    text-align: center;\n    background: transparent;\n  }\n  &.ant-input-number-focused {\n    box-shadow: none;\n    border-color: transparent;\n    background: transparent;\n    &:hover {\n      border-color: transparent;\n      background: transparent;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
