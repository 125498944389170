// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-tooltip .ant-tooltip-inner {
  color: #000000;
  /* 设置字体颜色为红色 */
  font-size: 14px;
  /* 可选：设置字体大小 */
  background-color: #ffffff;
  /* 可选：设置背景颜色 */
}
`, "",{"version":3,"sources":["webpack://./src/pages/Fip/approve/index.less"],"names":[],"mappings":"AAAA;EACI,cAAA;EACF,cAAc;EAAZ,eAAA;EAEF,cAAc;EADZ,yBAAA;EAGF,cAAc;AAChB","sourcesContent":[".custom-tooltip .ant-tooltip-inner {\n    color: #000000; /* 设置字体颜色为红色 */\n    font-size: 14px; /* 可选：设置字体大小 */\n    background-color: #ffffff; /* 可选：设置背景颜色 */\n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
